import styled from "styled-components";
import BurnBackground from "../assets/images/burnbackground.png";
import BurnLogo from "../assets/images/burnlogo.png";
import Image from "../components/Image/index";
import { Button, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import Mute from "../components/Mute/Mute";
import TwitterIcon from "@mui/icons-material/Twitter";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import contractAbi from "../contract-abi.json";
import BurnTable from "../components/Burn/BurnTable";
import BurnTableDisconnected from "../components/Burn/BurnTableDisconnected";
import BurnButton from "../assets/images/burnbutton.png";
import Loading from "../assets/images/ZpLoading.png";

export const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Zero Point Studios",
      infuraId: process.env.INFURA_KEY,
    },
  },
  // walletconnect: {
  //   package: WalletConnect,
  //   options: {
  //     infuraId: process.env.INFURA_KEY,
  //   },
  // },
};

const web3Modal = new Web3Modal({
  providerOptions, // required
});

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  .burn-logo {
    padding-top: 50px;
    @media screen and (max-width: 950px) {
      padding-top: 26px;
    }
    @media screen and (max-width: 799px) {
      width: 175px;
    }
    @media screen and (max-width: 510px) {
      padding-top: 10px;
      width: 150px;
    }
    @media screen and (max-width: 330px) {
      width: 50%;
    }
  }
  .nav-bar {
    align-items: center;
    position: absolute;
    top: 25px;
    right: 40px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 950px) {
      padding-top: 20px;
      top: 0;
      right: 0;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
    }
  }
  .nav-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .wallet-detail-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }
  .wallet-address {
    cursor: pointer;
    font-family: "Lato";
    padding-right: 8px;
    font-size: 15px;
    color: rgba(222, 222, 222, 0.7);
    @media screen and (max-width: 330px) {
      font-size: 10px;
    }
  }
  .wallet-button {
    border-radius: 0;
    font-family: "Lato";
    height: 20px;
    padding: 12px;
    font-size: 12px;
    margin-right: 20px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .disconnect-wallet-button {
    border-radius: 0;
    font-family: "Lato";
    height: 20px;
    padding: 12px;
    font-size: 12px;
    margin-right: 20px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .wallet-button-in-container {
    border-radius: 0;
    font-family: "Lato";
    color: #fff;
    width: 140px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 510px) {
      font-size: 10px;
      height: 24px;
      width: 100px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 10px;
    }
  }
  .wallet-button-in-container:hover {
    color: rgba(203, 181, 137, 1);
    border: 1px solid rgba(203, 181, 137, 1);
    -webkit-filter: drop-shadow(1px 1px 1px rgba(203, 181, 137, 0.6));
    filter: drop-shadow(1.25px 1.25px 1.25px rgba(203, 181, 137, 0.6));
  }
  .wallet-button:hover {
    color: rgba(203, 181, 137, 1);
    border: 1px solid rgba(203, 181, 137, 1);
    -webkit-filter: drop-shadow(1px 1px 1px rgba(203, 181, 137, 0.6));
    filter: drop-shadow(1.25px 1.25px 1.25px rgba(203, 181, 137, 0.6));
  }
  .disconnect-wallet-button:hover {
    color: rgba(203, 181, 137, 1);
    border: 1px solid rgba(203, 181, 137, 1);
    -webkit-filter: drop-shadow(1px 1px 1px rgba(203, 181, 137, 0.6));
    filter: drop-shadow(1.25px 1.25px 1.25px rgba(203, 181, 137, 0.6));
  }
  .pagination-colored-shard {
    width: 2px;
  }
  .pagination-non-colored-shard {
    width: 2px;
  }
  .twitter-icon {
    font-size: 23px;
    color: #dedede70;
    padding: 0px 20px 0 2px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .twitter-icon:hover {
    color: #1da1f2 !important;
  }
  .heading {
    padding-top: 50px;
    font-family: "Lato";
    font-weight: 400;
    color: #fff;
    @media only screen and (max-width: 775px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 510px) {
      padding-top: 25px;
      font-size: 20px;
    }
  }
  .sub-heading {
    max-width: 800px;
    font-family: "Lato";
    font-weight: 300;
    color: #fff;
    word-spacing: 2px;
    @media only screen and (max-width: 910px) {
      width: 85%;
    }
    @media only screen and (max-width: 510px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 14px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .burn-container {
    padding-top: 70px;
    width: 900px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 910px) {
      padding-top: 26px;
      width: 85%;
    }
    @media only screen and (max-width: 330px) {
      padding-top: 10px;
    }
  }
  .top-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 910px) {
      flex-direction: column;
    }
  }
  .token-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .burn-headers {
    color: #8d8d8d;
    font-family: "Lato";
    font-size: 16px;
    @media only screen and (max-width: 510px) {
      font-size: 14px;
      text-align: center;
      margin: 0;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 12px;
      text-align: center;
      margin: 0;
      margin-bottom: 10px;
    }
  }
  .burn-headers-tokens-selected {
    color: #8d8d8d;
    font-family: "Lato";
    font-size: 16px;
    @media only screen and (max-width: 510px) {
      font-size: 10px;
      margin: 0;
      margin-top: 10px;
    }
  }
  .token-id {
    padding-left: 10px;
    color: #fff;
    font-family: "Lato";
    font-size: 16px;
    @media only screen and (max-width: 510px) {
      font-size: 10px;
      margin: 0;
      margin-top: 10px;
      padding-left: 4px;
      padding-bottom: 10px;
    }
  }
  .burn-table-container {
    background: #000;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 20px;
  }
  .burn-table-connected {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media only screen and (max-width: 775px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 330px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .connect-text {
    color: #fff;
    font-size: 24px;
    font-family: "Lato";
    font-weight: 400;
    padding-bottom: 20px;
    @media only screen and (max-width: 775px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 510px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 10px;
    }
  }
  .burn-table-disconnected {
    display: flex;
    flex-direction: column;
    padding-top: 214px;
    padding-bottom: 214px;
    align-items: center;
    @media only screen and (max-width: 510px) {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  .token-container {
    cursor: pointer;
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 4px;
  }
  .active {
    border: 1px solid #cbb589;
  }
  .studiopass-video {
    height: 175px;
    width: 175px;
    overflow: hidden;
    min-width: 0;
    @media only screen and (max-width: 910px) {
      height: 150px;
      width: 150px;
    }
    @media only screen and (max-width: 510px) {
      height: 125px;
      width: 125px;
    }
    @media only screen and (max-width: 340px) {
      height: 75px;
      width: 75px;
    }
  }
  .token-ids {
    color: #fff;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 400;
    @media only screen and (max-width: 330px) {
      font-size: 10px;
      font-weight: 300;
    }
  }
  .buttons-container {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 510px) {
      padding-top: 20px;
    }
  }
  .confirm-button-container {
    display: flex;
    flex-direction: row;
  }
  .confirm-button {
    border-radius: 0;
    width: 120px;
    font-size: 15px;
    font-family: "Lato";
    height: 20px;
    padding: 18px 0px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 510px) {
      width: 110px;
      font-size: 12px;
    }
    @media only screen and (max-width: 330px) {
      width: 80px;
      font-size: 12px;
    }
  }
  .confirm-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
  .confirm-button:disabled,
  .confirm-button[disabled] {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
  .footer {
    padding-top: 80px;
    margin: 20px auto;
    color: white;
    @media only screen and (max-width: 650px) {
      padding-top: 50px;
      font-size: 10px;
    }
    @media only screen and (max-width: 510px) {
      padding-top: 40px;
    }
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
  }
  .pagination-container-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
  }

  .pagination-container {
    display: flex;
    list-style-type: none;
    padding: 0;

    .pagination-item {
      justify-content: center;
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(255, 255, 255, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(227, 57, 57, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: rgba(223, 42, 42, 0.08);
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: "";
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(255, 255, 255, 0.87);
          border-top: 0.12em solid rgba(255, 255, 255, 0.87);
        }

        &.left {
          transform: rotate(-135deg) translate(-50%);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid rgba(255, 255, 255, 0.43);
          border-top: 0.12em solid rgba(255, 255, 255, 0.43);
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
  .back-single-button-container {
    @media only screen and (max-width: 330px) {
      height: 20;
      padding: 0;
    }
  }
  .back-button {
    border-radius: 0;
    width: 120px;
    font-size: 15px;
    font-family: "Lato";
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 510px) {
      width: 50px;
      font-size: 12px;
    }
  }
  .back-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
  .burn-button-container {
    padding: 90px 219px;
    @media only screen and (max-width: 910px) {
      padding: 0;
      text-align: center;
      padding: 50px;
    }
  }
  .burn-button {
    cursor: pointer;
    @media only screen and (max-width: 910px) {
      width: 80%;
    }
  }
  .burn-button:hover {
    opacity: 0.9;
  }
  .goldenkey-container {
    padding-top: 40px;
    margin: 0 auto;
    width: 900px;
    margin-bottom: 30px;
    @media only screen and (max-width: 910px) {
      width: 600px;
    }
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .golden-echo-key-video {
    border: 1.45px solid #aba14a;
    width: 100%;
  }
  .golden-echo-key-video-two {
    border: 1.45px solid #aba14a;
    width: 100%;
    display: none;
  }
  .transaction-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 910px) {
      flex-direction: column;
    }
  }
  .transaction-text {
    cursor: pointer;
    font-family: "Lato";
    font-weight: 300;
    font-size: 15px;
    color: #fff;
    @media only screen and (max-width: 510px) {
      font-size: 12px;
    }
  }
  .loading-container {
    padding: 100px 0;
    text-align: center;
  }
  .loading {
    width: 100px;
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .anchor {
    text-decoration: none;
  }
  .no-tokens {
    color: #fff;
    padding-top: 242px;
    font-size: 24px;
    font-family: "Lato";
    font-weight: 400;
    padding-bottom: 214px;
    text-align: center;
    @media only screen and (max-width: 510px) {
      font-size: 16px;
      padding-top: 120px;
      padding-bottom: 74px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 12px;
      padding-top: 120px;
      padding-bottom: 74px;
    }
  }
`;

function BurnPage() {
  const [muted, setMuted] = useState(false);
  const [tokens, setTokens] = useState({
    tokens: [0],
    upgradeToken: 0,
    readyToBurn: false,
  });
  const [selectedToken, setSelectedToken] = useState(0);
  const [selectedBurnTokens, setSelectedBurnTokens] = useState<number[]>([0]);
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(0);
  const [isBurning, setIsBurning] = useState(false);
  const [burnTransaction, setBurnTransaction] = useState<any>();
  const [burningLoading, setBurningLoading] = useState(false);

  const [isConnected, setConnected] = useState(false);
  const [provider, setProvider] = useState<any>();
  const [library, setLibrary] = useState<any>();
  const [account, setAccount] = useState("");
  const [network, setNetwork] = useState<ethers.providers.Network>();
  const [chainId, setChainId] = useState<number>(0);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const goldenEchoKeyVideoElementRef = useRef<any>(null);
  const goldenEchoKeyVideoTwoElementRef = useRef<any>(null);

  const myCallback = useCallback(() => {
    if (!goldenEchoKeyVideoElementRef.current) return;

    goldenEchoKeyVideoElementRef.current.style.display = "none";
    goldenEchoKeyVideoTwoElementRef.current.style.display = "flex";
    goldenEchoKeyVideoTwoElementRef.current.play();
  }, [goldenEchoKeyVideoElementRef]);

  // useEffect(() => {
  //   if (goldenEchoKeyVideoElementRef.current) return;

  //   const element = goldenEchoKeyVideoElementRef.current;

  //   element?.addEventListener("ended", myCallback);

  //   return () => {
  //     element?.removeEventListener("ended", myCallback);
  //   };
  // }, [myCallback]);

  const handleEnded = () => {
    console.log("Video ended.");
    myCallback();
  };

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setNetwork(network);
      setChainId(network.chainId);
      setConnected(true);
    } catch (error: any) {
      setError(error);
    }
  };

  useEffect(() => {
    getTokens(account);
  }, [account]);

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
    setConnected(false);
    setTokens({ tokens: [0], upgradeToken: 0, readyToBurn: false });
    setSelectedToken(0);
    setSelectedBurnTokens([0]);
    // setTokens([0]);
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  const refreshState = () => {
    setAccount("");
    setChainId(0);
    setMessage("");
  };

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string) => {
        setAccount(accounts);
      };

      const handleChainChanged = (chainId: number) => {
        setChainId(chainId);
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  const handleNext = () => {
    setTokens({
      tokens: tokens.tokens.filter((token) => token !== selectedToken),
      upgradeToken: selectedToken,
      readyToBurn: false,
    });
  };

  const handleBackStep = () => {
    getTokens(account);
    setSelectedBurnTokens([0]);
  };

  const handleNextStep = () => {
    setTokens({
      tokens: selectedBurnTokens,
      upgradeToken: selectedToken,
      readyToBurn: true,
    });
  };

  const getTokens = async (accountAddress: string) => {
    let nftTx: any;
    let tokensCheck = [];
    try {
      const signer = await library.getSigner();
      const zeroPointContract = new ethers.Contract(
        "0x6B5d28442aF2444F66F8f2883Df30089E3fb840E",
        contractAbi,
        signer
      );
      nftTx = await zeroPointContract.tokensOfOwner(accountAddress);
      for (let i = 0; i < nftTx.length; ) {
        let checkMaster = await zeroPointContract.currentEchoKeys(
          "Master",
          parseInt(nftTx[i]._hex)
        );
        if (!checkMaster) {
          let token = parseInt(nftTx[i]._hex);
          tokensCheck.push(token);
        }
        i++;
      }
      setTokens({ tokens: tokensCheck, upgradeToken: 0, readyToBurn: false });
    } catch (error: any) {
      setError(error);
      return;
    }
  };

  const burnBabyBurn = async () => {
    try {
      setBurningLoading(true);
      const signer = await library.getSigner();
      const zeroPointContract = new ethers.Contract(
        "0x6B5d28442aF2444F66F8f2883Df30089E3fb840E",
        contractAbi,
        signer
      );
      let transaction = await zeroPointContract.upgrade(tokens.upgradeToken, [
        tokens.tokens[0],
        tokens.tokens[1],
        tokens.tokens[2],
        tokens.tokens[3],
        tokens.tokens[4],
        tokens.tokens[5],
      ]);
      setBurnTransaction(await transaction.wait());
    } catch (error: any) {
      setError(error);
      return;
    }
    setIsBurning(true);
    setBurningLoading(false);
  };

  const handleToggleMute = () => {
    setMuted((current) => !current);
    if (goldenEchoKeyVideoElementRef.current) {
      goldenEchoKeyVideoElementRef.current.muted = !muted;
    }
    if (goldenEchoKeyVideoTwoElementRef.current) {
      goldenEchoKeyVideoTwoElementRef.current.muted = !muted;
    }
  };

  return (
    <StyledDiv
      style={{
        background: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${BurnBackground})`,
      }}
    >
      <div className="nav-bar">
        {isConnected ? (
          <div className="wallet-detail-container" onClick={disconnect}>
            <p className="wallet-address">
              {account?.slice(0, account.length - 36)}
              ...
              {account?.slice(account.length - 5, account.length)}
            </p>
            <Button className="disconnect-wallet-button">Disconnect</Button>
          </div>
        ) : (
          <div className="wallet-detail-container">
            <Button className="wallet-button" onClick={connectWallet}>
              Connect
            </Button>
          </div>
        )}
        <div className="nav-icons">
          <div onClick={handleToggleMute} className="mute-button">
            <Mute isMuted={muted} />
          </div>
        </div>
      </div>
      <Image
        className="burn-logo"
        src={BurnLogo}
        alt="Zero Point Studios Logo"
      ></Image>
      {tokens.readyToBurn === false ? (
        <>
          <h1 className="heading">WELCOME TO THE END</h1>
          <h3 className="sub-heading">
            Burning 6 Echo Keys to upgrade to a Master Echo Key will unlock
            exclusive and enhanced benefits towards all IP, productions and
            events across the Zero Point ecosystem.
          </h3>
          <div className="burn-container">
            <div className="top-line">
              {tokens.upgradeToken !== 0 ? (
                <h3 className="burn-headers">SELECT TOKENS YOU WISH TO BURN</h3>
              ) : (
                <h3 className="burn-headers">
                  SELECT TOKEN YOU WISH TO UPGRADE
                </h3>
              )}
              <div className="token-header">
                {tokens.upgradeToken !== 0 ? (
                  <>
                    <h3 className="burn-headers-tokens-selected">
                      TOKENS SELECTED
                    </h3>
                    {selectedBurnTokens[0] !== 0 ? (
                      <>
                        {selectedBurnTokens.map((selectedBurnToken, index) => {
                          return (
                            <h3 key={index} className="token-id">
                              #{selectedBurnToken}
                            </h3>
                          );
                        })}
                      </>
                    ) : (
                      <h3 className="token-id">NONE</h3>
                    )}
                  </>
                ) : (
                  <>
                    <h3 className="burn-headers-tokens-selected">
                      TOKEN SELECTED
                    </h3>
                    {selectedToken ? (
                      <h3 className="token-id">#{selectedToken}</h3>
                    ) : (
                      <h3 className="token-id">NONE</h3>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="burn-table-container">
              {tokens.tokens[0] !== 0 ? (
                <BurnTable
                  tokens={tokens.tokens}
                  upgradeToken={tokens.upgradeToken}
                  selectedToken={selectedToken}
                  setSelectedToken={setSelectedToken}
                  setSelectedTokenIndex={setSelectedTokenIndex}
                  selectedBurnTokens={selectedBurnTokens}
                  setSelectedBurnTokens={setSelectedBurnTokens}
                />
              ) : (
                <BurnTableDisconnected
                  connectWallet={connectWallet}
                  isConnected={isConnected}
                />
              )}
            </div>
            {isConnected &&
              (tokens.upgradeToken !== 0 ? (
                <div
                  className="buttons-container"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="back-button-container">
                    <Button className="confirm-button" onClick={handleBackStep}>
                      BACK
                    </Button>
                  </div>
                  <div className="confirm-button-container">
                    <Button
                      className="confirm-button"
                      onClick={handleNextStep}
                      disabled={selectedBurnTokens.length < 6}
                    >
                      CONFIRM
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className="buttons-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div className="confirm-button-container">
                    <Button
                      className="confirm-button"
                      onClick={handleNext}
                      disabled={tokens.tokens.length < 7 || selectedToken === 0}
                    >
                      CONFIRM
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : isBurning ? (
        <>
          <h1 className="heading">A NEW JOURNEY BEGINS</h1>
          <div onClick={handleToggleMute} className="goldenkey-container">
            <video
              id="golden-echo-key-video"
              ref={goldenEchoKeyVideoElementRef}
              src="https://d1yetyopisjxoj.cloudfront.net/transform-golden-key.mp4"
              className="golden-echo-key-video"
              webkit-playsinline="true"
              playsInline
              autoPlay
              onEnded={handleEnded}
            ></video>
            <video
              id="golden-echo-key-video-two"
              ref={goldenEchoKeyVideoTwoElementRef}
              src="https://d20e5lud46p6fa.cloudfront.net/GoldenEchoKey.mp4"
              className="golden-echo-key-video-two"
              webkit-playsinline="true"
              playsInline
              preload={"auto"}
              loop
            ></video>
            <div className="transaction-container">
              <a
                className="anchor"
                href={`https://etherscan.io/tx/${burnTransaction.transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="transaction-text">
                  CLICK TO VIEW ETHERSCAN TRANSACTION
                </h2>
              </a>
              <a
                className="anchor"
                href={`https://opensea.io/assets/ethereum/0x6b5d28442af2444f66f8f2883df30089e3fb840e/${tokens.upgradeToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="transaction-text">CLICK TO VIEW ON OPENSEA</h2>
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className="heading">ONE FINAL STEP</h1>
          <h3 className="sub-heading">
            The end of one journey marks the beginning of another. You have
            finally arrived at this moment and from it, there no turning back.
            As the phoenix is purified by fire and born anew, so too must your
            key shed its former self to reveal its true form underneath. Do not
            fear, greater things await you on the other side.
          </h3>
          <div className="burn-container">
            <div className="top-line">
              <h3 className="burn-headers-tokens-selected">
                UPGRADING TOKEN{" "}
                <span className="token-id">#{tokens.upgradeToken}</span>
              </h3>
              <div className="token-header">
                <h3 className="burn-headers-tokens-selected">BURNING</h3>
                {tokens.tokens.map((burnToken, index) => {
                  return (
                    <h3 key={index} className="token-id">
                      #{burnToken}
                    </h3>
                  );
                })}
              </div>
            </div>
            <div className="burn-table-container">
              {burningLoading ? (
                <div className="loading-container">
                  <img className="loading" src={Loading} alt="Loading"></img>
                </div>
              ) : (
                <div className="burn-button-container">
                  <img
                    className="burn-button"
                    src={BurnButton}
                    alt="Burn Button"
                    onClick={() => {
                      burnBabyBurn();
                    }}
                  ></img>
                </div>
              )}
            </div>
            <div
              className="buttons-container"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="back-single-button-container"
                style={{ justifyContent: "flex-start" }}
              >
                <Button className="back-button" onClick={handleBackStep}>
                  BACK
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="footer">
        <Typography className="copyright" variant="body2">
          Copyright &copy; 2023 Zero Point | All Rights Reserved
        </Typography>
      </div>
    </StyledDiv>
  );
}

export default BurnPage;
