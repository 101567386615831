import { SetStateAction, useEffect, useMemo, useState } from "react";
import Pagination from "../Pagination/Pagination";
import Video from "../Video";

const BurnTable = ({
  tokens,
  selectedToken,
  setSelectedToken,
  setSelectedTokenIndex,
  selectedBurnTokens,
  setSelectedBurnTokens,
  upgradeToken,
}: {
  tokens: Array<number>;
  selectedToken: number;
  setSelectedToken: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTokenIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedBurnTokens: number[];
  setSelectedBurnTokens: React.Dispatch<React.SetStateAction<number[]>>;
  upgradeToken: number;
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const PageSize = windowSize < 910 ? 4 : 8;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tokens.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tokens, PageSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      {upgradeToken !== 0 ? (
        <>
          <div className="burn-table-connected">
            {currentTableData.map((token, index) => (
              <div
                key={index}
                onClick={() => {
                  if (selectedBurnTokens[0] === 0) {
                    selectedBurnTokens.pop();
                  }
                  if (selectedBurnTokens.includes(token)) {
                    setSelectedBurnTokens((tokens) =>
                      tokens.filter((burnToken) => burnToken !== token)
                    );
                  } else if (selectedBurnTokens.length !== 6) {
                    setSelectedBurnTokens([...selectedBurnTokens, token]);
                  }
                }}
                className={`token-container ${
                  selectedBurnTokens.includes(token) && "active"
                }`}
              >
                <Video />
                <h3 className="token-ids">TOKEN #{token}</h3>
              </div>
            ))}
          </div>
          <div className="pagination-container-table">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={tokens.length}
              pageSize={PageSize}
              onPageChange={(page: SetStateAction<number>) =>
                setCurrentPage(page)
              }
            />
          </div>
        </>
      ) : (
        <>
          {tokens.length === 0 ? (
            <>
              <div className="no-tokens">NO ECHO KEYS FOUND</div>
            </>
          ) : (
            <>
              <div className="burn-table-connected">
                {currentTableData.map((token, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedToken(token);
                      setSelectedTokenIndex(index);
                    }}
                    className={`token-container ${
                      token === selectedToken && "active"
                    }`}
                  >
                    <Video />
                    <h3 className="token-ids">TOKEN #{token}</h3>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="pagination-container-table">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={tokens.length}
              pageSize={PageSize}
              onPageChange={(page: SetStateAction<number>) =>
                setCurrentPage(page)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default BurnTable;
