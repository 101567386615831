export const wallets = [
  {
    type: "raffle",
    walletAddress: "0xE91C4eC4a439Ec9d6b11ef8d1dF11B35142df7C9",
  },
  {
    type: "raffle",
    walletAddress: "0x92E23d20FaC1E040B665242d3fEF2540A14FbbD3",
  },
  {
    type: "raffle",
    walletAddress: "0xe10772c3c2e8879b13d5d2886eF8e9f9B95b83Aa",
  },
  {
    type: "raffle",
    walletAddress: "0xCb6a1FF7AabBE2AFCEB4f7C5A6eC7Ded94506C50",
  },
  {
    type: "raffle",
    walletAddress: "0x8A030eC9E0FfeD4E4c2Af1cf6a7820a18282EFd2",
  },
  {
    type: "raffle",
    walletAddress: "0xbE51C0a50bE87AE158d1dcb8000619f20ccC2803",
  },
  {
    type: "raffle",
    walletAddress: "0xcf61d17Ace0A81D8703653E03E692938B7639879",
  },
  {
    type: "raffle",
    walletAddress: "0xfA1690f2778F1F78c24DA9F33eb63c8965293E84",
  },
  {
    type: "raffle",
    walletAddress: "0xAE9Ff3EE8f18fFC85daf249820a92dD7Cb8D0007",
  },
  {
    type: "raffle",
    walletAddress: "0x8662399A2B270227a6F49E910E984eF3dd43C18D",
  },
  {
    type: "raffle",
    walletAddress: "0x438cE10D583C0786a30477b8AcF5E0C62773812e",
  },
  {
    type: "raffle",
    walletAddress: "0x6EDb7D704503ED8762183449A1eEb64b10627C49",
  },
  {
    type: "raffle",
    walletAddress: "0x48fA64CD712338e59BD9Ec62557DF7D133FfC469",
  },
  {
    type: "raffle",
    walletAddress: "0x45EC1f7dDa3DC498c37df2c37fA389592d699538",
  },
  {
    type: "raffle",
    walletAddress: "0xc7A2789C634a257C385AdBC7243E1c13B8c11A13",
  },
  {
    type: "raffle",
    walletAddress: "0x459dA68FBF9e09c30ccDaACC377dA70F4Ea7bd27",
  },
  {
    type: "raffle",
    walletAddress: "0xb7e6c740C03e9a50A26A08E367b6786A47F690bb",
  },
  {
    type: "raffle",
    walletAddress: "0xf6843599d50f804D1d6Bab9b84137b92ca53f327",
  },
  {
    type: "raffle",
    walletAddress: "0x656827da0ad7fD8B934d49Baa82A68018f7a23BB",
  },
  {
    type: "raffle",
    walletAddress: "0x7a1B7f07034F80A357b361367c3b32e35AdB0912",
  },
  {
    type: "raffle",
    walletAddress: "0xCB17C8e39Ec71Ec7d9D0738275Eb0963A9aBC680",
  },
  {
    type: "raffle",
    walletAddress: "0x56cF30cC1F8E12B2F0C9E0Fd36a701d718de133e",
  },
  {
    type: "raffle",
    walletAddress: "0xB6EbEDE463122331c88674DEF61ec9002a5b7267",
  },
  {
    type: "raffle",
    walletAddress: "0x911765e6cbf6F1dfc7C1BACC01be7e32612aa541",
  },
  {
    type: "raffle",
    walletAddress: "0x9615A87530d02326dBe1030ab4D9692BD89377cd",
  },
  {
    type: "raffle",
    walletAddress: "0x2e7159C72f58FF370f37dede9b56779E24986E54",
  },
  {
    type: "raffle",
    walletAddress: "0x9d231de90d6dfE9c3BcF6d0aE2b677057e277228",
  },
  {
    type: "raffle",
    walletAddress: "0x3e1Cd56662F2462860e37E55b90F33D71fB7E398",
  },
  {
    type: "raffle",
    walletAddress: "0x59B0C32345289252B7009773a1d233A7e1765c23",
  },
  {
    type: "raffle",
    walletAddress: "0xfe78D5613146614f21DE3C5629C51bD77fF36539",
  },
  {
    type: "raffle",
    walletAddress: "0x28c1Ed3cA6289F8E0C6B68508c1B7Fc00372001E",
  },
  {
    type: "raffle",
    walletAddress: "0x79A8bf1b2929c98Af2d2a0587d2e9C3f834F7920",
  },
  {
    type: "raffle",
    walletAddress: "0xCF6b5261df69cCe2073202bec58F98F9A43fB715",
  },
  {
    type: "raffle",
    walletAddress: "0x8D3bA5963c380f98e42616bF87240bbEDCc2f1fE",
  },
  {
    type: "raffle",
    walletAddress: "0x53dd2794D2245FD9A9c18BF76ab0c4705dBEb20c",
  },
  {
    type: "raffle",
    walletAddress: "0x2E370bF6e49A1EEC4f02Bc2A75890c4297e84996",
  },
  {
    type: "raffle",
    walletAddress: "0x511406CB0b446a4Cde4EeA637d8Bff4eF78FC910",
  },
  {
    type: "raffle",
    walletAddress: "0xc624beC0c386E0F8Ed8AAD8762EA91721cEE17b1",
  },
  {
    type: "raffle",
    walletAddress: "0x6aF772008ce91533e58EE936462F2095BBa0b277",
  },
  {
    type: "raffle",
    walletAddress: "0xB0c7d369089931064aBb38CC1E92Eb9F12563020",
  },
  {
    type: "raffle",
    walletAddress: "0xB2d5a39113688394626a62a7C9848570950464C2",
  },
  {
    type: "raffle",
    walletAddress: "0xDE6FABa7620F6486263151C6a7455773573a5Da8",
  },
  {
    type: "raffle",
    walletAddress: "0xe3DC7EF467c14cF73d28b94053e80d30C467dFD8",
  },
  {
    type: "raffle",
    walletAddress: "0xeEdE8026751068D62F2e4B7d79aE812d61D083c7",
  },
  {
    type: "raffle",
    walletAddress: "0xeEeEbFbb40b4dd080d6cb654C6D9B6DDFB208dFf",
  },
  {
    type: "raffle",
    walletAddress: "0x593EA2f05B7E90eE5Ca5B747ee24D7a76ef21E89",
  },
  {
    type: "raffle",
    walletAddress: "0x605bC0eC8EB4C356fc28B7290C96464b328Bc6d1",
  },
  {
    type: "raffle",
    walletAddress: "0xF1D8eaDE65271Bcd008f7c7AC0F1467f5C675a26",
  },
  {
    type: "raffle",
    walletAddress: "0x76d06E8E4cc5b949F2e668E98Df62A00B663C6e8",
  },
  {
    type: "raffle",
    walletAddress: "0xC33B888B6573Ce96e26014EB985400ea0b078024",
  },
  {
    type: "raffle",
    walletAddress: "0xF0eBBcA36Dba291beF5b9B3E398059b7c1d44893",
  },
  {
    type: "raffle",
    walletAddress: "0x080443e2343FEb1cE8DcCbb6950709ed2802D2E8",
  },
  {
    type: "raffle",
    walletAddress: "0xcC870B2fa5e6bcc8aDD0aA4DD0816De2d555662C",
  },
  {
    type: "raffle",
    walletAddress: "0x60C1a749C390d2E18Ff84EBc17c74a28fac0c6C9",
  },
  {
    type: "raffle",
    walletAddress: "0xDFB25c3cCa69037d6F5DF1D9Ff08F6932153BA6E",
  },
  {
    type: "raffle",
    walletAddress: "0xB1B2710035c4f0bc265dA9BC36EBA2c000c18fE7",
  },
  {
    type: "raffle",
    walletAddress: "0x74aADAe2c7aA9321314A0A6F53a70eda5ebe103a",
  },
  {
    type: "raffle",
    walletAddress: "0xF1393dCD027D6137A76632f5fde27fCF9BB3bbD0",
  },
  {
    type: "raffle",
    walletAddress: "0x5A4dbFF4C26A32B4df219C0872067f7878049b6d",
  },
  {
    type: "raffle",
    walletAddress: "0xDFA9E1fa1566E894cd288644f7cC393EB5835DE5",
  },
  {
    type: "raffle",
    walletAddress: "0xb975Eb5065a99b6576BD76e5D14505D655AB314a",
  },
  {
    type: "raffle",
    walletAddress: "0xCc28da221917425886325d4124d25eCCB47C97B0",
  },
  {
    type: "raffle",
    walletAddress: "0x6cD83eA9d3c349D72B11Ac725E4C79779732bCD5",
  },
  {
    type: "raffle",
    walletAddress: "0xf935861a8b8cc6867Eeb463D97b501c69D177092",
  },
  {
    type: "raffle",
    walletAddress: "0x14cFe9C7fCFcB610E22b1c6Cf1b41b1b7F615523",
  },
  {
    type: "raffle",
    walletAddress: "0x9D2CE3599B6a4Ae41f7D7dB75f1351d9995E8C9B",
  },
  {
    type: "raffle",
    walletAddress: "0x86bC1Bcf197a9c694Ac391B000459D35cC8A1DA1",
  },
  {
    type: "raffle",
    walletAddress: "0x19BE4b217C8054649C9E5AE8A6BaE6564236F78c",
  },
  {
    type: "raffle",
    walletAddress: "0x16453478073881d65A4B5DDB0b213B02B64540B1",
  },
  {
    type: "raffle",
    walletAddress: "0xB3A280105397DE429Ac53b76fF80A95De10eD5A4",
  },
  {
    type: "raffle",
    walletAddress: "0xcb9B2C8d734c934934F34B5DE7C7D060A727dC08",
  },
  {
    type: "raffle",
    walletAddress: "0x387a39d5BF3d4C43Dc3d8c8c0Ac674BbF43B0Dca",
  },
  {
    type: "raffle",
    walletAddress: "0x1DcBaa9BA8E7a42b18e8519D783b46128E47a433",
  },
  {
    type: "raffle",
    walletAddress: "0x08F5a976b99e01f0237773186E2CF5C821Cf7B01",
  },
  {
    type: "raffle",
    walletAddress: "0xBF9e2d8a0BE736d3a8d8b4eb668A3D041f467cb2",
  },
  {
    type: "raffle",
    walletAddress: "0x26d9EcC89693C41D28fe3148Cd17A58b0Ed09FCF",
  },
  {
    type: "raffle",
    walletAddress: "0xA17da93faefb89d90b92cbbB59538ED1c3c8AEE3",
  },
  {
    type: "raffle",
    walletAddress: "0x0f17BCbB017A49a98c6A0096b0a225143741ccfb",
  },
  {
    type: "raffle",
    walletAddress: "0x42c3653EA90f39B01D4E60ec934DbA238bD8Cf49",
  },
  {
    type: "raffle",
    walletAddress: "0x9AC95e1Fa6d04f1c9D81Acb1D9EDC1B18FC52E07",
  },
  {
    type: "raffle",
    walletAddress: "0x1260245470260ac805fc5D8af56EF5EF5D35f870",
  },
  {
    type: "raffle",
    walletAddress: "0xf81105Bed988b8a633935cEbe9E042A84a333375",
  },
  {
    type: "raffle",
    walletAddress: "0xdA594AA2F8d5F5Ae4c33005467198ADB725b1bc2",
  },
  {
    type: "raffle",
    walletAddress: "0x4706A5bb1C63e2de151f6a4f038002EaF509cf88",
  },
  {
    type: "raffle",
    walletAddress: "0x098774c89B27de3617220Abf060FA91eE2ee1C85",
  },
  {
    type: "raffle",
    walletAddress: "0xdA0b452000d4BaBAb2bBaaa26a92Ab0609783886",
  },
  {
    type: "raffle",
    walletAddress: "0x13c22f175cB60f50678BF8634bFA82879FF48e0A",
  },
  {
    type: "raffle",
    walletAddress: "0x90Fe2bC19cd408137F7999C1499EA7463046d373",
  },
  {
    type: "raffle",
    walletAddress: "0x7445C64795cbc5099886b297E0d5A417B431644a",
  },
  {
    type: "raffle",
    walletAddress: "0xC314d5a519E399a274323716056525e6Cc301738",
  },
  {
    type: "raffle",
    walletAddress: "0x50A20EAEfD8E7C803a6A4fc387460161120a48fA",
  },
  {
    type: "raffle",
    walletAddress: "0x850c2228A32e0F491db4a82408ee3349Ad315eA5",
  },
  {
    type: "raffle",
    walletAddress: "0xB16bF21c1CF9657A53AFD09FEA1bd6d94b597Ad5",
  },
  {
    type: "raffle",
    walletAddress: "0x7cb1E892B5A510362673634b8bE2FFc24875f22A",
  },
  {
    type: "raffle",
    walletAddress: "0x4c6462750243CbdE4B26b2CbbF33AF658ceeAC40",
  },
  {
    type: "raffle",
    walletAddress: "0xAC57AC75ED0f1Db4C66aC7F6D401207f8102F539",
  },
  {
    type: "raffle",
    walletAddress: "0xEaa32E4954c2443ACC8B8e76ab9462797c7fa092",
  },
  {
    type: "raffle",
    walletAddress: "0xc2aC96d93dA81096ae710e141b156217b4f21c46",
  },
  {
    type: "raffle",
    walletAddress: "0x5401fcdfb888A39D75096a06d7e07e0192716784",
  },
  {
    type: "raffle",
    walletAddress: "0xa70889f0a8d8d59cF6ac46399882c4B1BbFd0f4F",
  },
  {
    type: "raffle",
    walletAddress: "0x600C2165477d1fb4e8b199D23FcC9F666d3CcB6e",
  },
  {
    type: "raffle",
    walletAddress: "0x92b3153Cf037a539d4fDD2a5dE2fCdB3A2b82F42",
  },
  {
    type: "raffle",
    walletAddress: "0x23c9015dfB7eb0DFab26E501770A321D58cADE28",
  },
  {
    type: "raffle",
    walletAddress: "0x02FEBCf625702cA17c960D4a551EEa870A5e55fC",
  },
  {
    type: "raffle",
    walletAddress: "0x9A203E7D733f4fb87b6bdA144f39a2e2B45f8e61",
  },
  {
    type: "raffle",
    walletAddress: "0x79E58c5637a85eB24234CDb3ef51986191ab55d8",
  },
  {
    type: "raffle",
    walletAddress: "0x1b8b4dC094C92Bd01CA33f6d133CF1A9C9bf654b",
  },
  {
    type: "raffle",
    walletAddress: "0xe7e665449E43E3F1E495B712f6bc47e3a3f3488a",
  },
  {
    type: "raffle",
    walletAddress: "0xf5c1c534385A6Fca1993a10505f0e950cb5C2E0E",
  },
  {
    type: "raffle",
    walletAddress: "0x05572eD2B5C46BB1CCfB8dc7B370cD69288aB69B",
  },
  {
    type: "raffle",
    walletAddress: "0xf49A726f148f0AD60362f593fF3e81F9192E2e53",
  },
  {
    type: "raffle",
    walletAddress: "0xfc32C7f30837955a9Dd0D16F4166Dde6fC3acB9a",
  },
  {
    type: "raffle",
    walletAddress: "0x4593762b151c295AC5DfBB35F210E5F4487F3fff",
  },
  {
    type: "raffle",
    walletAddress: "0xcCed09eB640a02c035AdB9EB1c71b503Fb083fcA",
  },
  {
    type: "raffle",
    walletAddress: "0xC11143b512880d2dc9654EC76170A549dEB50567",
  },
  {
    type: "raffle",
    walletAddress: "0x4DA3B349BaCBc9db43E69d699C7A87E31d9b81f7",
  },
  {
    type: "raffle",
    walletAddress: "0xBd445EBd5e5b3BFd51a8CB62AfF9f99cD95eA4A9",
  },
  {
    type: "raffle",
    walletAddress: "0xc645A7BA7c55dC064E2bc90dD3D539D7dc4F7158",
  },
  {
    type: "raffle",
    walletAddress: "0xfDe873aA9b9aF42623B17a3FE8E9F02575f61747",
  },
  {
    type: "raffle",
    walletAddress: "0xF0E62da2396a902ab785735E7ba4895a38844B77",
  },
  {
    type: "raffle",
    walletAddress: "0x2aB96914c9BB1287930887E2221d7DEE0f5aE40E",
  },
  {
    type: "raffle",
    walletAddress: "0x4da7f062310250e37d2F0d47b504e154174D2943",
  },
  {
    type: "raffle",
    walletAddress: "0xa14DF635476258114CB67E64760aB9C40745bbdC",
  },
  {
    type: "raffle",
    walletAddress: "0x7F25e217738F321D467BD40F5910C2ed2908a21c",
  },
  {
    type: "raffle",
    walletAddress: "0x3CA7167f00b7c372A1CdbC4fc18E18582324454b",
  },
  {
    type: "raffle",
    walletAddress: "0x26FC7c6057Be8abdD3Ac3173639A639B49e1c408",
  },
  {
    type: "raffle",
    walletAddress: "0x8e3824517ae9516fb87819D32f940bB89126b4d2",
  },
  {
    type: "raffle",
    walletAddress: "0x421F0227ca0C0282f3aef7Bd3Daa154677f1a6fa",
  },
  {
    type: "raffle",
    walletAddress: "0x0334e5ee6ef8A5c990408b26929d5aE5Cbcb4284",
  },
  {
    type: "raffle",
    walletAddress: "0x565Ea6fBb7EA7EB639e1be15dC1E9C9FB75065bB",
  },
  {
    type: "raffle",
    walletAddress: "0x0Da62Cf40C53601e7Ce743B3254edF9341353673",
  },
  {
    type: "raffle",
    walletAddress: "0x61288C24780418372FbBc76415f1457e6c4827c0",
  },
  {
    type: "raffle",
    walletAddress: "0x88AF0d9DF76B5D2f64d989c2Ed8eAa40E71E8B1B",
  },
  {
    type: "raffle",
    walletAddress: "0x8ad262af18e10588FB78bCF925440E6CAA962B63",
  },
  {
    type: "raffle",
    walletAddress: "0x9C19CcFB1A8b6cF84Fa6d40E275F6B4C2EB5f1C2",
  },
  {
    type: "raffle",
    walletAddress: "0x2A94f40075b116575EDe62489569870B9E817134",
  },
  {
    type: "raffle",
    walletAddress: "0xAa6e7CB7A29f6fb60b3EB999A3ba53f0Bba5c4D2",
  },
  {
    type: "raffle",
    walletAddress: "0x365e98153B630827e6A1806739fCCc70747f28B1",
  },
  {
    type: "raffle",
    walletAddress: "0xA91F89e13675b161e3DD025eEeA47BFEA8762F85",
  },
  {
    type: "raffle",
    walletAddress: "0x22b7e48750972F2657c25Fd23D5070918eB3b68D",
  },
  {
    type: "raffle",
    walletAddress: "0xaf2C8efc509FD84A72d12bcE07f71c10Cb19760A",
  },
  {
    type: "raffle",
    walletAddress: "0x74Da47551eBafb6565a1C661e719e7BE310280b8",
  },
  {
    type: "raffle",
    walletAddress: "0xBA1a26B3d1F82853e7A320e6EB78f3B129d8e028",
  },
  {
    type: "raffle",
    walletAddress: "0x0Decb04d7f0685d196BEb845a62356ff2C4098fd",
  },
  {
    type: "raffle",
    walletAddress: "0x6904AFaeF5CcB87322591A545de49d92829662a6",
  },
  {
    type: "raffle",
    walletAddress: "0x5ea6838036e80f29F8E06C07e2bd225aa324E956",
  },
  {
    type: "raffle",
    walletAddress: "0xc31440748bC461217E19EdA76aaf8145Bf9a45BD",
  },
  {
    type: "raffle",
    walletAddress: "0x8572f08DdeCC9e13b24A0fda86c1dBb874caa7ba",
  },
  {
    type: "raffle",
    walletAddress: "0x35D963A95D7b93eD767AeDF40A8CAa74b300Ec01",
  },
  {
    type: "raffle",
    walletAddress: "0x8D99290A7C4F939007A3F7A2E2Faa2fB4142574a",
  },
  {
    type: "raffle",
    walletAddress: "0xe694A26EF5d3159097a87Ef7BD8448A96B8f3710",
  },
  {
    type: "raffle",
    walletAddress: "0xee82A14B3AC07Edd8b6053C8f511E3aF0D13E59d",
  },
  {
    type: "raffle",
    walletAddress: "0xC3961f5A6616FdccA77B47D51289b4343382532C",
  },
  {
    type: "raffle",
    walletAddress: "0x6C4766C85c9a854cbaeAbEF8E1e205B2fd56FbA5",
  },
  {
    type: "raffle",
    walletAddress: "0x17c610B6AeFb4A0E9E811856c86825C4099487e4",
  },
  {
    type: "raffle",
    walletAddress: "0xDaA6a1C53fa62c1b2eD3C8C73ee015A8C41544bc",
  },
  {
    type: "raffle",
    walletAddress: "0xeF288140f9aE34f906BcF7a505eB6CC1F7964876",
  },
  {
    type: "raffle",
    walletAddress: "0x7AF77FBEF6ABc6036c9D25791bA5A80d0EaaFAB5",
  },
  {
    type: "raffle",
    walletAddress: "0xEF5F15e6cE4CB0CEb5a5FB40eb8C8AbEb06f898A",
  },
  {
    type: "raffle",
    walletAddress: "0x1a51d7c43862d0f417ea4ed1b4ebcAbb24B8DBDF",
  },
  {
    type: "raffle",
    walletAddress: "0xBBd4c5c9Dd5080Ce539Cf0254BC02c7C5a59a182",
  },
  {
    type: "raffle",
    walletAddress: "0x857c5F36874f899958B4f82348f6fb0cf4535bF5",
  },
  {
    type: "raffle",
    walletAddress: "0x50ECf4A4217d2A8d7aA1584EE5ce6E9800B4d29c",
  },
  {
    type: "raffle",
    walletAddress: "0x252893876F45d5bb6b2981f4622126D4149Aa17f",
  },
  {
    type: "raffle",
    walletAddress: "0x9f5323c75626E0424865E0D01546Fc95823b071B",
  },
  {
    type: "raffle",
    walletAddress: "0x0278aFD108cD72d7569EaB228b7CD8d1980b4cA1",
  },
  {
    type: "raffle",
    walletAddress: "0x762E317C0773CfDe8AdA11105c9c3BD071C81349",
  },
  {
    type: "raffle",
    walletAddress: "0x3A56c4A27aD46dA83B0fb337875dc3b1dEC4EDa8",
  },
  {
    type: "raffle",
    walletAddress: "0x1111d713EB675934a6dB43167Ec66733667CAB44",
  },
  {
    type: "raffle",
    walletAddress: "0x40ac0e21DD87ddc259bB5810571bC6f4a710244f",
  },
  {
    type: "raffle",
    walletAddress: "0x6bD83Bb2EeeF70EbeCa4bbDc65768600C16FC17d",
  },
  {
    type: "raffle",
    walletAddress: "0xfB18421FCFeaeD1b972cdC6aeb2A4d3A890aa1eF",
  },
  {
    type: "raffle",
    walletAddress: "0x4671bF243240B30108f75f65102e2B6725cBab3A",
  },
  {
    type: "raffle",
    walletAddress: "0xb06b0E1F00FE3Cf4c8Afd6D31d702Ebae6585eeF",
  },
  {
    type: "raffle",
    walletAddress: "0x674489f633E8CC4df1130F9bC15063e9a5d3B4Ef",
  },
  {
    type: "raffle",
    walletAddress: "0xb271b694e686215F37e15FAFe12B1E2E015739B7",
  },
  {
    type: "raffle",
    walletAddress: "0xC3613C992CAD6521E89a64b29Dacf2F95304abf2",
  },
  {
    type: "raffle",
    walletAddress: "0x8dc68186C740abC1FfE61df349891FDa1cA2c71B",
  },
  {
    type: "raffle",
    walletAddress: "0x45682FFaefCbc5c2467dCF3b98B2bA23Cd6730aC",
  },
  {
    type: "raffle",
    walletAddress: "0x7806ED4057A2B348a34224BfDef58fAD0AEDe640",
  },
  {
    type: "raffle",
    walletAddress: "0xEd3AFd345EE37B6a0881Db261b0e9f7513695B32",
  },
  {
    type: "raffle",
    walletAddress: "0x8e6C4b5CAcb3255530a32cf15203A440a1918EA7",
  },
  {
    type: "raffle",
    walletAddress: "0x3f2AE6E6ff92ebd9c933C9083dDb8186e715cf48",
  },
  {
    type: "raffle",
    walletAddress: "0xE6449DfF1E2E2C8805A8E48c64b77F413beB8edc",
  },
  {
    type: "raffle",
    walletAddress: "0xce19b0fC8366c08aDe51E2Bb3f92Ec87bBEF927e",
  },
  {
    type: "raffle",
    walletAddress: "0xB0E56acF3c2973b61aD92eA456858221489e5355",
  },
  {
    type: "raffle",
    walletAddress: "0xFDE812386cc64B750584AD091bddeE35527F105E",
  },
  {
    type: "raffle",
    walletAddress: "0x723c8462B3C6751B0B0B10a52299d2837505fD5E",
  },
  {
    type: "raffle",
    walletAddress: "0xea2Fe5c0F5A5B0CDF8E259f79c35Ee456fb8f1EE",
  },
  {
    type: "raffle",
    walletAddress: "0xa6cBB32CBAf494767C498864444FBe0d2B9f9066",
  },
  {
    type: "raffle",
    walletAddress: "0x9673BF5FCe0fA5316D7b77CABFdECa2bBC554ddb",
  },
  {
    type: "raffle",
    walletAddress: "0x59A6983c507015072FF68Cbef104f2B4d4e9E227",
  },
  {
    type: "raffle",
    walletAddress: "0x2a0Ed44cbEA5B0AD579E29e586516D4DfDBA6598",
  },
  {
    type: "raffle",
    walletAddress: "0xCfc07DBFe249A25D9626DB83A96B69b29e03f5Be",
  },
  {
    type: "raffle",
    walletAddress: "0xCF7c682a159349B698e3Eb516ae7dEEB042Af890",
  },
  {
    type: "raffle",
    walletAddress: "0x33E49BF73e668f1d1121a2ffD1f6ED43A68Ad592",
  },
  {
    type: "raffle",
    walletAddress: "0x75F47c0460D66D9141E9fC12d4C5dB3Bdc62e533",
  },
  {
    type: "raffle",
    walletAddress: "0xE650b435a9Ce0b30D9f36c87E22D76a632b33450",
  },
  {
    type: "raffle",
    walletAddress: "0x5d64af3a3f3c16D92b8f3375720E6D8A46A94Da5",
  },
  {
    type: "raffle",
    walletAddress: "0xE09035250e82E9a8685d8975E0A98937F66bED2f",
  },
  {
    type: "raffle",
    walletAddress: "0x6FDa68a14698a871fc28470Ba9F7E5EC9ef05fdC",
  },
  {
    type: "raffle",
    walletAddress: "0x9664cEA7E2dC2A6C06bEaBe60a530924fa209D8F",
  },
  {
    type: "raffle",
    walletAddress: "0x2Aa6bC4BF2644766086Ccdc7c1CA863209D779A3",
  },
  {
    type: "raffle",
    walletAddress: "0xa63872a970Fc5BffdFea4cC0d40Ec09a05bCe3Df",
  },
  {
    type: "raffle",
    walletAddress: "0xf942C152E0F2D8c1464F4FCBB8331E98eED857C6",
  },
  {
    type: "raffle",
    walletAddress: "0xB20974AcDe0F5deb7f934353A2ed38285B014D80",
  },
  {
    type: "raffle",
    walletAddress: "0xb1c34ddbbbE361FAa32B0a7dA79C4abe152abaa2",
  },
  {
    type: "raffle",
    walletAddress: "0x05B526E37cf7A3acBe92485702BCeA58A8c2c637",
  },
  {
    type: "raffle",
    walletAddress: "0x5d1F127eA338958107D9a6E97ce76F0e2D078eBA",
  },
  {
    type: "raffle",
    walletAddress: "0x9289D2b4E7682Bd1ca40E76E06177ad1a95d356A",
  },
  {
    type: "raffle",
    walletAddress: "0x0acCe16B43D7e1CEe8B2b00700716CdcE7605C79",
  },
  {
    type: "raffle",
    walletAddress: "0x2243f79e0c28001415Bd41769364e8a1cC45a7CF",
  },
  {
    type: "raffle",
    walletAddress: "0x4D3c83aA3A39837174eD9BCB05234aD35C0f196b",
  },
  {
    type: "raffle",
    walletAddress: "0xfB16ef83856b2aa12BAc1fC35893F4467f8D6ad8",
  },
  {
    type: "raffle",
    walletAddress: "0x44800575B836770734a77304bC24cA44fD64aEb3",
  },
  {
    type: "raffle",
    walletAddress: "0xE757Cff1f6aE1528BD229567B66A7EaF4F20710F",
  },
  {
    type: "raffle",
    walletAddress: "0x0e35b11F218e61C70E50CD970Db3C68F0F254CBE",
  },
  {
    type: "raffle",
    walletAddress: "0x5d8F8aeda4c31CdD7506aC7efd7348f2b4C54dc9",
  },
  {
    type: "raffle",
    walletAddress: "0xF042a99afD3851C26A83B0F6093728fBBb5A1145",
  },
  {
    type: "raffle",
    walletAddress: "0x91a7781356735A74Aa69bDE618B5872c18328754",
  },
  {
    type: "raffle",
    walletAddress: "0x841802f6BB00C2033080EF83074456eC6C517f61",
  },
  {
    type: "raffle",
    walletAddress: "0xD2B74EB8aA866dA2d53e1d4607fB089eee1a1E3E",
  },
  {
    type: "raffle",
    walletAddress: "0x464b6eFda334F1D793286E1F7Ba6F64A46cC6987",
  },
  {
    type: "raffle",
    walletAddress: "0x2d24Ac209FE0633f2D72e9888b851711c2f1dd30",
  },
  {
    type: "raffle",
    walletAddress: "0xbC7ecAD1d03f1575F3c7119b653f758c7DfA3740",
  },
  {
    type: "raffle",
    walletAddress: "0x1cce34B6BE8cd55C1B5978641b5ee22Fb88ccbb9",
  },
  {
    type: "raffle",
    walletAddress: "0x4eA2dF62784279C487C257b0545C1443Fbb9d530",
  },
  {
    type: "raffle",
    walletAddress: "0x40786a212Deb50b1A484Fa7A26dd2b7064eD7e02",
  },
  {
    type: "raffle",
    walletAddress: "0x1FF4FfF2a1CA245F8589B98982Ea625AcB78bd40",
  },
  {
    type: "raffle",
    walletAddress: "0x895930092f4716EaD9B60A8D29551Eb890DE4D55",
  },
  {
    type: "raffle",
    walletAddress: "0x46342eA6D4c71e35290b69BF080e969a5099D20b",
  },
  {
    type: "raffle",
    walletAddress: "0x5458a306b6088D5c641e0dae2a234FCD6C592075",
  },
  {
    type: "raffle",
    walletAddress: "0x06B181369289D8cE2643b8A0F4758f70D8446d79",
  },
  {
    type: "raffle",
    walletAddress: "0x39383AbB3703FD2760CE4cc9dd91bd410b06e8E1",
  },
  {
    type: "raffle",
    walletAddress: "0xb66F04D8e1923E2A3FC3D0ad4CC1Ea0356faA92b",
  },
  {
    type: "raffle",
    walletAddress: "0x4f81573a58b1f2A1c188dC380d75C0C64e8b083a",
  },
  {
    type: "raffle",
    walletAddress: "0x0121ead445c098210a1905C00027e4832B381734",
  },
  {
    type: "raffle",
    walletAddress: "0x04fe941719e27dd6cC0f58e6e780A23879118201",
  },
  {
    type: "raffle",
    walletAddress: "0x8a73Df870e2F9D9B7f8685E9D02f44EE44A2Eb3e",
  },
  {
    type: "raffle",
    walletAddress: "0xbF64cFAC8DD3A9502daF39820796fBcc13F44AE8",
  },
  {
    type: "raffle",
    walletAddress: "0x7ADF6b5a9222e6628f3040cb867fAC97993d03c5",
  },
  {
    type: "raffle",
    walletAddress: "0x7b6185eC774fa8e42892d23876f3B5A73c88aC01",
  },
  {
    type: "raffle",
    walletAddress: "0xf068A511c76DffabF0F6390c1f37F3A06dCC52Db",
  },
  {
    type: "raffle",
    walletAddress: "0x44E1e9271B18e33eDa96E1B70577a9dc2fAbFd8f",
  },
  {
    type: "raffle",
    walletAddress: "0x4ad7292490833A4A2EC17C0d01879adDE8772986",
  },
  {
    type: "raffle",
    walletAddress: "0x6dbf0ae2e4690d49ff6Eea11088A9b97dF385794",
  },
  {
    type: "raffle",
    walletAddress: "0xBd5C8dB99ab62AD9f2c83bd679cE02C4c9bE254c",
  },
  {
    type: "raffle",
    walletAddress: "0xC8c092044a8F68c941d042fCde937777A09a2072",
  },
  {
    type: "raffle",
    walletAddress: "0x2158159C65059cb6803A6179280c22941832e54F",
  },
  {
    type: "raffle",
    walletAddress: "0xD3E0D46FeC5f2EfC471d1bB32B1B67f2093b86D4",
  },
  {
    type: "twitter",
    walletAddress: "0x338C8bA36ab46E9DF84BCe741F903D01895ACAD5",
  },
  {
    type: "twitter",
    walletAddress: "0xF980e8272234174Aa39d59C2D542fC51362e8701",
  },
  {
    type: "twitter",
    walletAddress: "0x9e51125107f16dCcd26BeEA44888e9d5c94ED2Fe",
  },
  {
    type: "twitter",
    walletAddress: "0x580baCF1C4C05864695905A9823DEc48B12dE118",
  },
  {
    type: "twitter",
    walletAddress: "0x2bDb1FA14e54375244b3A2d0146F2D9D71A06C24",
  },
  {
    type: "twitter",
    walletAddress: "0xca81CeE6EBb2db8a20251bF6f6Fd973712d21923",
  },
  {
    type: "twitter",
    walletAddress: "0x80Ae87eFf4614622518A82Ff933861ab9000d1Ac",
  },
  {
    type: "twitter",
    walletAddress: "0x8aF0B9A9B751E086122bC340188Bd9d99b8C7ec1",
  },
  {
    type: "twitter",
    walletAddress: "0xfF0c68CdC0Dd46A6eD8ba68e887A2a673C46F4E6",
  },
  {
    type: "twitter",
    walletAddress: "0x1Dad11121b527Fd385369477E4DC2aA5dB976849",
  },
  {
    type: "twitter",
    walletAddress: "0xeA35A3B01CB0ED383c6182F308373d519d0d6350",
  },
  {
    type: "twitter",
    walletAddress: "0x39a9755448D35163716698A21846f2Bf65D3fAe9",
  },
  {
    type: "twitter",
    walletAddress: "0x95664898aB0f77D340cBB8F2862673cce5e906CA",
  },
  {
    type: "premint",
    walletAddress: "0x0bc4f3d965a30145e1ba0b09dae84c133818aa9c",
  },
  {
    type: "premint",
    walletAddress: "0x0e2ce9123ef30142f8ef8365ea2cbea06596e482",
  },
  {
    type: "premint",
    walletAddress: "0x0e7862162b180928c4baeb7109d617dc3c243b83",
  },
  {
    type: "premint",
    walletAddress: "0x1165d16c2424311e1d93a1772514438758ffc984",
  },
  {
    type: "premint",
    walletAddress: "0x122a4dd4b8b95b36f3987055f7d52d16589299d1",
  },
  {
    type: "premint",
    walletAddress: "0x12314c256835A94cD0038704de11D394E341b36B",
  },
  {
    type: "premint",
    walletAddress: "0x1830a2e92ce6bf9eac68e99692584544ff284024",
  },
  {
    type: "premint",
    walletAddress: "0x1b145997895bdc2079e6c77bca9e071b63dbe36d",
  },
  {
    type: "premint",
    walletAddress: "0x1e0c9a9be675805645a66c393b847cd392d9faba",
  },
  {
    type: "premint",
    walletAddress: "0x1e3fc5f2e892ec161b27d04db08dd49524238fa0",
  },
  {
    type: "premint",
    walletAddress: "0x249b10a3321274ecdc822200ec1837a12679379a",
  },
  {
    type: "premint",
    walletAddress: "0x24e6063ac956d2b3526efd763caa074f8f7630e3",
  },
  {
    type: "premint",
    walletAddress: "0x27aed96ff4bde010949a65f9d28ef1ae65c952cd",
  },
  {
    type: "premint",
    walletAddress: "0x2d5e03d9064a55b96d34290a176656398df91379",
  },
  {
    type: "premint",
    walletAddress: "0x2e132d1e719d2f0d7fc8e40b008837d279455243",
  },
  {
    type: "premint",
    walletAddress: "0x302b6cdd92959126a2de294c4ebc7589fed1146d",
  },
  {
    type: "premint",
    walletAddress: "0x32ecf437edd0a457e65ae507f95f8e0d48e7d8f6",
  },
  {
    type: "premint",
    walletAddress: "0x362f05fd54dd40f8156eb7689410b66db677e77f",
  },
  {
    type: "premint",
    walletAddress: "0x3888d3a7d5e546b7cab85007488b522f01f639de",
  },
  {
    type: "premint",
    walletAddress: "0x3aac333b9687703a3a653f8f6dcce890cde4c5fa",
  },
  {
    type: "premint",
    walletAddress: "0x46835d4acb6e79acb7d6af0a7f1605bb4aaaeebd",
  },
  {
    type: "premint",
    walletAddress: "0x4b8f884698d0f0532e706a02b9751135290c23ac",
  },
  {
    type: "premint",
    walletAddress: "0x4c98191c3c90155e2b937329ae724d9e9f7df515",
  },
  {
    type: "premint",
    walletAddress: "0x4dff14747bda2651dbe43d41e29e17ad05a6ae50",
  },
  {
    type: "premint",
    walletAddress: "0x5438ad15e0bd200b1e2257ac3f031e8cf6b6c7c4",
  },
  {
    type: "premint",
    walletAddress: "0x5898f2d6b5c52849efcd52405538d78405f893d7",
  },
  {
    type: "premint",
    walletAddress: "0x59122b7dd9189dcbf002bb9393cbbc9d362bef76",
  },
  {
    type: "premint",
    walletAddress: "0x5bdea3efdb16ac04da99b062c93bce5a7a3ae61a",
  },
  {
    type: "premint",
    walletAddress: "0x5fB998B8024E38867558fD5E2b87B6D13Da730f8",
  },
  {
    type: "premint",
    walletAddress: "0x6510b218d9d775ae2d223332f6a05aaf5c18188d",
  },
  {
    type: "premint",
    walletAddress: "0x65286e266256968ed2d53270e460099dc360002e",
  },
  {
    type: "premint",
    walletAddress: "0x725a70b1847d1bf3fbb7dcaf43880a9343f308be",
  },
  {
    type: "premint",
    walletAddress: "0x725a70b1847d1bf3fbb7dcaf43880a9343f308be",
  },
  {
    type: "premint",
    walletAddress: "0x73630e72a1232baace02a3d0f4b87daf6b812892",
  },
  {
    type: "premint",
    walletAddress: "0x7c70b34bfa2526b5a05a5c5c95f04af08b2ee677",
  },
  {
    type: "premint",
    walletAddress: "0x829664cd7cb0f05a9ffeea8efeb8861ebc75c2d6",
  },
  {
    type: "premint",
    walletAddress: "0x871776e739a9675d7e4cb9a67881ce75061e18eb",
  },
  {
    type: "premint",
    walletAddress: "0x943b570d050ecb2f5d8ee5cb6bb5521e23bde95e",
  },
  {
    type: "premint",
    walletAddress: "0x997cec591fcf61e9d52070a6bf8567fd718f13ec",
  },
  {
    type: "premint",
    walletAddress: "0x9bb568c29be8bba5b4acf9684c7ee6e9532e0581",
  },
  {
    type: "premint",
    walletAddress: "0xa1bc22a1317d1c7d497c22541ad1343c4b0dd43e",
  },
  {
    type: "premint",
    walletAddress: "0xa3be2ac4ab9b188b73460215b59f01ceb45d21d5",
  },
  {
    type: "premint",
    walletAddress: "0xa7fb80b759ea191c19720f531398663b298455fd",
  },
  {
    type: "premint",
    walletAddress: "0xae72c6a6fad9fa9d82d089e1ebf73b3043855425",
  },
  {
    type: "premint",
    walletAddress: "0xaf203113904f639db23421930f5c741e09660fe5",
  },
  {
    type: "premint",
    walletAddress: "0xb5ba25231cb906e68e4bae0e7bbe6ecd972be883",
  },
  {
    type: "premint",
    walletAddress: "0xBb042645879Ea51D6f088511c15803D225C92b9f",
  },
  {
    type: "premint",
    walletAddress: "0xbf2c089f3e9d23aa7d124c4b4e8371a54300fd5e",
  },
  {
    type: "premint",
    walletAddress: "0xc0942cbefcef70541ed81cda3512cf16f6bcc6e3",
  },
  {
    type: "premint",
    walletAddress: "0xc1b3233d8eee29feafb52708720efe0db05c9d73",
  },
  {
    type: "premint",
    walletAddress: "0xc41bc363c02c574f1243ada3009e5e01facc62d7",
  },
  {
    type: "premint",
    walletAddress: "0xc628a8ded94f00f04eb00462ae02c62f6ca96519",
  },
  {
    type: "premint",
    walletAddress: "0xca1ee6abeaa8d61c9d3e73c94d7bdb9fbc114138",
  },
  {
    type: "premint",
    walletAddress: "0xdb79947aeb425b158eef7e29ec25a93d5b5a065a",
  },
  {
    type: "premint",
    walletAddress: "0xde70bdf7dfe13614d281716813274380a59e3e5d",
  },
  {
    type: "premint",
    walletAddress: "0xe0a15f73cf155384307d67b2f28719b1fc301519",
  },
  {
    type: "premint",
    walletAddress: "0xe3b72489968f11c15282514f33df24634440393f",
  },
  {
    type: "premint",
    walletAddress: "0xeaf7814cdf7236bc5992d19cc455f9c92b00aa9e",
  },
  {
    type: "premint",
    walletAddress: "0xf12bc9f9aa470d8b9d582515540db95facb101bf",
  },
  {
    type: "premint",
    walletAddress: "0xf2ed7c200d77d6cf7d7f4bc43188354a4a48f43f",
  },
  {
    type: "premint",
    walletAddress: "0x120346525c584da519083ef3d2c53e8c70bf2244",
  },
  {
    type: "premint",
    walletAddress: "0x2a5ad14250f9eeb2cd6598c59e2786f70c171b65",
  },
  {
    type: "premint",
    walletAddress: "0x61c71a61936a82e1229b45c0982da66f844b0500",
  },
  {
    type: "premint",
    walletAddress: "0x7d57df72cc14bce0057fb2f8f17c1f6233e60966",
  },
  {
    type: "premint",
    walletAddress: "0x86a3719c7682ba9b702e9551a9027fda7da815fa",
  },
  {
    type: "premint",
    walletAddress: "0x8a3922d36af4e68c21ef532c19277dcfae2bde3f",
  },
  {
    type: "premint",
    walletAddress: "0x92e23d20fac1e040b665242d3fef2540a14fbbd3",
  },
  {
    type: "premint",
    walletAddress: "0xB29479Ef88dFFf8daC466cF6C716e7985c4241c5",
  },
  {
    type: "premint",
    walletAddress: "0xd2c3786c78f72feff2ffea550ef18e1284611408",
  },
  {
    type: "premint",
    walletAddress: "0xfbcb3377b0abd6927b2d5831b023d013d275adaf",
  },
];
