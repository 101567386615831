import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import StudioPass from "./studio-pass/StudioPass";
import HomePage from "./home-page/HomePage";
import BurnPage from "./burn-page/BurnPage";
import ThankYouPage from "./thankyou-page/ThankYou";
import AboutPage from "./about-page/About";
import WalletCheckerPage from "./wallet-checker/WalletChecker";
import NaacalPage from "./naacal-page/NaacalPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/studiopass" element={<StudioPass />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/burn" element={<BurnPage />} />
        {/* <Route path="/completed" element={<ThankYouPage />} /> */}
        <Route path="/wallet" element={<WalletCheckerPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/naacal" element={<NaacalPage />} />
      </Routes>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
