import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Enter from "../Enter/Enter";

const StyledTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  @media only screen and (max-width: 650px) {
    height: 200px;
  }
  #title {
    font-family: "Lato";
    font-size: 58px;
    color: #dedede;
    font-variant: small-caps;
    margin-bottom: 80px;
    text-align: center;
    white-space: pre-wrap;
    line-height: 0.9em;
    @media only screen and (max-width: 650px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 24px;
    }
  }
  .welcome-text {
    font-size: 40px;
    @media only screen and (max-width: 650px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-none {
    display: none;
  }
  .fade-in {
    display: block;
    animation: fadeIn 6s;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 2s ease;
  }

  .fade-in-button {
    display: block;
    animation: fadeIn 4s;
  }

  #enter-button {
    font-family: "Lato";
    font-size: 28px;
    color: #dedede70;
    width: 50%;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
  #enter-button:hover {
    color: white;
    background-color: black;
  }
`;

const Title = ({
  stateChanger,
  playVideo,
}: {
  stateChanger: React.Dispatch<React.SetStateAction<boolean>>;
  playVideo: () => void;
}) => {
  const [fadeProp, setFadeProp] = useState({ fade: "fade-none" });
  const [enteredFade, setEnteredFade] = useState(false);

  useEffect(() => {
    if (fadeProp.fade === "fade-none") {
      setTimeout(() => {
        setFadeProp({ fade: "fade-in" });
      }, 1000);
    }

    if (enteredFade) {
      setFadeProp({
        fade: "fade-out",
      });
    }
  }, [enteredFade]);

  return (
    <StyledTitleDiv>
      <Typography id="title" className={fadeProp.fade}>
        <span className="welcome-text">welcome to</span>
        <br></br>a world without strings
      </Typography>
      <Enter
        playVideo={playVideo}
        stateChanger={stateChanger}
        enteredFade={setEnteredFade}
      />
    </StyledTitleDiv>
  );
};

export default Title;
