/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useEffect, useState } from "react";
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderImg?: string;
}
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export default ({ placeholderImg, src, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    return () => {
      img.removeEventListener("load", onLoad);
    };
  }, [src, onLoad]);

  return <img {...props} alt={imgSrc} src={imgSrc} />;
};
