import { Typography } from "@mui/material";
import nadaText from "../assets/images/nada.png";
import line from "../assets/images/Line.png";
import styled from "styled-components";
import { useRef, useState } from "react";
import Navbar from "../components/NavBar/Navbar";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  background-color: #000;
  background-position-x: center;
  a {
    text-decoration: none;
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .element-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 600px;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .paragraph-one {
    font-family: "Lato";
    color: #fff;
    font-size: 15px;
    text-align: justify;
    margin-bottom: 30px;
    line-height: 18px;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      margin-bottom: 25px;
    }
  }
  .about-page-container {
    width: 500px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .about-page-video-container {
    width: 600px;
    margin-bottom: 30px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
  }
  .about-page-video {
    border: 1.45px solid #cbb589;
    width: 100%;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .about-page {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .about-page-header {
    font-size: 34px;
    padding-bottom: 20px;
    font-weight: 500;
    text-align: center;
    font-family: "Lato";
    color: #898989;
  }
  .echo-key {
    font-family: "Lato";
    color: #fff;
    margin-bottom: 20px;
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
    text-align: left;
  }
  .paragraph-two {
    font-family: "Lato";
    font-size: 15px;
    color: #fff;
    text-align: justify;
    margin-bottom: 40px;
    line-height: 18px;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
  .line {
    margin: 20px auto;
    width: 100%;
    padding-top: 50px;
  }
  .journey-text {
    text-align: center;
    padding-top: 20px;
    color: #504a3e;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  .nada-anchor {
    display: inline-block;
    padding-top: 40px;
    width: 70%;
    margin: 0 auto;
    @media only screen and (max-width: 450px) {
      width: 64%;
    }
  }
  .nada {
    width: 100%;
  }
  .line-two {
    width: 40%;
    padding-top: 24px;
    margin: 0 auto;
  }
  .footer {
    margin: 20px auto;
    color: white;
    @media only screen and (max-width: 650px) {
      margin-top: 50px;
      font-size: 10px;
    }
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
  }
  .details {
    font-family: "Lato";
    color: #fff;
    font-size: 15px;
    margin-bottom: 60px;
    text-align: justify;
    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
    }
  }
  .nada-anchor-list {
    text-decoration: none;
    color: #cbb589;
  }
  .burn-anchor {
    font-family: "Lato";
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
  }
  .burn-anchor:hover {
    color: #cbb589;
  }
  .nada-container {
    width: 450px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 300px;
    }
  }
  .nada {
    width: 100%;
  }
  .studio-pass-container {
    width: 350px;
    margin: 0px auto;
    @media only screen and (max-width: 650px) {
      width: 230px;
    }
  }
  .studio-pass-header {
    font-size: 40px;
    margin: 0;
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
    font-family: "Cinzel";
    letter-spacing: 6px;
    color: #cbb589;
    @media only screen and (max-width: 650px) {
      font-size: 16px;
    }
  }
  .litepaper-anchor {
    font-family: "Lato";
    font-size: 15px;
    text-decoration: none;
    color: #cbb589;
    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }
  }
  .litepaper-anchor:hover {
    opacity: 0.7;
  }
`;

function AboutPage() {
  const [muted, setMuted] = useState(true);
  const handleToggleMute = () => {
    setMuted((current) => !current);
    if (!studioPassVideoElementRef.current) return;
    studioPassVideoElementRef.current.muted = !muted;
  };
  const studioPassVideoElementRef = useRef<HTMLVideoElement>(null);

  return (
    <StyledDiv className="about-page-page">
      <Navbar />
      <div className="element-container">
        <div className="about-page-container">
          <h1 className="about-page-header">ABOUT US</h1>
        </div>
        <Typography className="paragraph-one">
          Zero Point is a Web3 entertainment studio that focuses on IP creation
          through live-action films tied to an NFT ecosystem. We currently have
          two live-action feature films in development along with their
          respective Web3 experiences and collections.
        </Typography>
        <Typography className="paragraph-one">
          Our mission is to create an IP Universe for the metaverse. With 40+
          fully developed stories in our vault, our catalogue of diverse IP
          spans across multiple genres, worlds, settings, time periods and
          characters, all of which combine into ONE interconnected storyline. We
          call this the Echoverse.
        </Typography>
        <Typography className="paragraph-one">
          To learn more about Zero Point and the other elements of our ecosystem
          including Verudian Artifacts, the Echosis and our IP catalogue, read
          our{" "}
          <a
            className="litepaper-anchor"
            href="https://litepaper.zeropointstudios.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LITEPAPER
          </a>
          .
        </Typography>
        <img className="line" src={line} alt="Line" />
        <div className="nav-container">
          <Typography className="journey-text">
            CONTINUE YOUR JOURNEY:
          </Typography>
          <a
            className="nada-anchor"
            target="_blank"
            rel="noopener noreferrer"
            href="https://nadaofthesun.com/"
          >
            <img className="nada" src={nadaText} alt="Nada Da Sun" />
          </a>
          <img className="line-two" src={line} alt="Line" />
          <div className="studio-pass-container">
            <a href="./studiopass">
              <h1 className="studio-pass-header">STUDIO PASS</h1>
              {/* <img className="studio-pass" src={studioPass} alt="Studio Pass" /> */}
            </a>
          </div>
        </div>
        <div className="footer">
          <Typography className="copyright" variant="body2">
            Copyright &copy; 2023 Zero Point | All Rights Reserved
          </Typography>
        </div>
      </div>
    </StyledDiv>
  );
}

export default AboutPage;
