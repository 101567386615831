import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const Enter = ({
  stateChanger,
  enteredFade,
  playVideo,
}: {
  stateChanger: React.Dispatch<React.SetStateAction<boolean>>;
  enteredFade: React.Dispatch<React.SetStateAction<boolean>>;
  playVideo: () => void;
}) => {
  const [fadeButtonProp, setFadeButtonProp] = useState({ fade: "fade-none" });
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      setFadeButtonProp({
        fade: "fade-out",
      });
      setTimeout(() => {
        stateChanger(true);
      }, 3000);
    }

    if (fadeButtonProp.fade === "fade-none") {
      setTimeout(() => {
        setFadeButtonProp({ fade: "fade-in-button" });
      }, 3500);
    }
  }, [clicked]);

  return (
    <Button
      onClick={() => {
        enteredFade(true);
        setClicked(true);
        playVideo();
      }}
      id="enter-button"
      className={fadeButtonProp.fade}
    >
      Enter
    </Button>
  );
};

export default Enter;
