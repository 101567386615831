import { Button, Typography } from "@mui/material";

const BurnTableDisconnected = ({
  connectWallet,
  isConnected,
}: {
  connectWallet: () => Promise<void>;
  isConnected: boolean;
}) => {
  return (
    <div className="burn-table-disconnected">
      {!isConnected && (
        <>
          <Typography className="connect-text">
            CONNECT WALLET TO VIEW ECHO KEYS
          </Typography>
          <Button
            className="wallet-button-in-container"
            onClick={connectWallet}
          >
            CONNECT
          </Button>
        </>
      )}
    </div>
  );
};
export default BurnTableDisconnected;
