import React, { useRef, useState } from "react";
import styled from "styled-components";
import "../App.css";
import Menu from "../components/Menu/Menu";
import Fade from "@mui/material/Fade";
import Mute from "../components/Mute/Mute";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Button, Typography } from "@mui/material";
import HomepageNavbar from "../components/NavBar/HomepageNavbar";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #000;
  overflow: hidden;
  .home-page-hero {
    width: 100%;
    min-height: 850px;
    /* aspect-ratio: 1440 / 810; */
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    aspect-ratio: 16/9;
    @media only screen and (max-width: 1268px) {
      aspect-ratio: unset;
      background-position: center;
    }
    .background-video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hero-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00000075;
      z-index: 0;
    }
    .hero-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0 auto;

      @media only screen and (max-width: 600px) {
        width: 300px;
      }
      @media only screen and (max-width: 0px) {
        width: 100px;
      }
    }
    .m-fadeOut {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }
    .m-fadeIn {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }
  a {
    text-decoration: none;
  }
  .burn-page-button {
    border-radius: 0;
    margin-top: 6px;
    width: 120px;
    font-size: 14px;
    height: 30px;
    font-family: "Lato";
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 510px) {
      font-size: 12px;
    }
  }
  .burn-page-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
`;

function HomePage() {
  const backgroundVideoElementRef = useRef<HTMLVideoElement>(null);
  const [showElements] = useState(true);
  const [muted, setMuted] = useState(true);
  const handleToggleMute = () => {
    setMuted((current) => !current);
    if (!backgroundVideoElementRef.current) return;
    backgroundVideoElementRef.current.muted = !muted;
  };

  return (
    <StyledDiv className="home-page">
      <section className="home-page-hero">
        <video
          ref={backgroundVideoElementRef}
          className="background-video"
          webkit-playsinline="true"
          playsInline
          loop
          muted
          autoPlay
        >
          <source
            src="https://d3a9yvt4b4y7et.cloudfront.net/background.mp4"
            type="video/mp4"
          />
        </video>
        <div className="hero-container">
          <HomepageNavbar
            showElements={showElements}
            muted={muted}
            handleToggleMute={handleToggleMute}
          />
          <div className="hero-content">
            <Fade
              in={showElements}
              style={{ transitionDelay: "100ms", transitionDuration: "2s" }}
            >
              <div>
                <Menu />
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </StyledDiv>
  );
}

export default HomePage;
