import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./App.css";
// import backgroundVideo from "./assets/videos/background.mp4";
import Menu from "./components/Menu/Menu";
import Fade from "@mui/material/Fade";
import Title from "./components/Title/Title";
import HomepageNavbar from "./components/NavBar/HomepageNavbar";

const StyledDiv = styled.div<{ entered: Boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #000;
  overflow: hidden;
  .home-page-hero {
    width: 100%;
    min-height: 850px;
    /* aspect-ratio: 1440 / 810; */
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    aspect-ratio: 16/9;
    @media only screen and (max-width: 1268px) {
      aspect-ratio: unset;
      background-position: center;
    }
    .background-video {
      display: none;
      ${(p) =>
        p.entered &&
        "display: block; position: absolute; width: 100%; height: 100%; object-fit: cover;"};
    }
    .hero-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00000075;
      z-index: 0;
    }
    .hero-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0 auto;

      @media only screen and (max-width: 600px) {
        width: 300px;
      }
    }
    .hero-content-loader {
      ${(p) =>
        !p.entered
          ? "display: flex; align-items: center; justify-content: center; height: 100%; margin: 0 auto;"
          : "display: none;"};
    }
    .m-fadeOut {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }
    .m-fadeIn {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }
`;

function App() {
  const backgroundVideoElementRef = useRef<HTMLVideoElement>(null);
  const [showElements, setShowElements] = useState(false);
  const [muted, setMuted] = useState(false);
  const [entered, setEntered] = useState(false);
  const handleToggleMute = () => {
    setMuted((current) => !current);
    if (!backgroundVideoElementRef.current) return;
    backgroundVideoElementRef.current.muted = !muted;
  };

  useEffect(() => {
    if (entered) {
      setTimeout(function () {
        setShowElements(true);
      }, 10000);
    }
  }, [entered]);

  const playVideo = async () => {
    if (!backgroundVideoElementRef.current) return;

    backgroundVideoElementRef.current.play();
    backgroundVideoElementRef.current.pause();

    await new Promise((r) => setTimeout(r, 3100));
    backgroundVideoElementRef && backgroundVideoElementRef.current.play();
  };

  return (
    <StyledDiv className="home-page" entered={entered}>
      <section className="home-page-hero">
        <Fade
          in={entered}
          style={{ transitionDelay: "100ms", transitionDuration: "4s" }}
        >
          <video
            ref={backgroundVideoElementRef}
            src="https://d3a9yvt4b4y7et.cloudfront.net/background.mp4"
            className="background-video"
            webkit-playsinline="true"
            playsInline
            loop
          ></video>
        </Fade>
        {entered && (
          <div className="hero-container">
            <HomepageNavbar
              showElements={showElements}
              muted={muted}
              handleToggleMute={handleToggleMute}
            />
            <div className="hero-content">
              {showElements && (
                <Fade
                  in={showElements}
                  style={{ transitionDelay: "100ms", transitionDuration: "2s" }}
                >
                  <div>
                    <Menu />
                  </div>
                </Fade>
              )}
            </div>
          </div>
        )}
        <div className="hero-content-loader">
          <Title playVideo={playVideo} stateChanger={setEntered} />
        </div>
      </section>
    </StyledDiv>
  );
}

export default App;
