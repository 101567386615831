import { useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 188px;
    width: 188px;
    transition: opacity 400ms ease 0ms;
  }
`;

const Video = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const src = "https://du51mi9iawse7.cloudfront.net/echo_key_burn_page.mp4";
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  return (
    <StyledDiv>
      <video
        className="studiopass-video"
        webkit-playsinline="true"
        loop
        autoPlay
        playsInline
        muted
        src={src}
        onLoadedData={onLoadedData}
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      />
    </StyledDiv>
  );
};
export default Video;
