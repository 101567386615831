import { Typography } from "@mui/material";
import styled from "styled-components";
import "../../App.css";
import studioPass from "../../assets/images/studio-pass-button-alone.png";
import zpLogo from "../../assets/images/ZP-Logo.png";
import nadaText from "../../assets/images/nada-button-alone.png";

const StyledMenuDiv = styled.div`
  .element-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    @media only screen and (max-width: 650px) {
      margin-top: -30px;
    }
  }
  .zp-logo-container {
    width: 200px;
    margin: 0px auto;
    margin-bottom: 320px;
    @media only screen and (max-width: 650px) {
      width: 150px;
      margin-bottom: 380px;
    }
  }
  .zp-logo {
    width: 100%;
  }
  .nada-container {
    width: 450px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 300px;
    }
  }
  .nada {
    width: 100%;
  }
  .studio-pass-container {
    width: 350px;
    margin: 0px auto;
    @media only screen and (max-width: 650px) {
      width: 230px;
    }
  }
  .studio-pass {
    width: 100%;
  }
  .studio-pass-header {
    font-size: 45px;
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
    font-family: "Cinzel";
    letter-spacing: 6px;
    color: #cbb589;
    @media only screen and (max-width: 650px) {
      font-size: 26px;
    }
  }
  .footer {
    margin: 0 auto;
    color: #dedede;
    padding-top: 80px;
    @media only screen and (max-width: 650px) {
      padding-top: 54px;
      font-size: 10px;
    }
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
  }
`;

const Menu = () => {
  return (
    <StyledMenuDiv>
      <div className="element-container">
        <div className="zp-logo-container">
          <img className="zp-logo" src={zpLogo} alt="Zero Point Logo" />
        </div>
        <div className="nada-container">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://nadaofthesun.com/"
          >
            <img className="nada" src={nadaText} alt="Nada Da Sun" />
          </a>
        </div>
        <div className="studio-pass-container">
          <a href="./studiopass">
            <h1 className="studio-pass-header">STUDIO PASS</h1>
          </a>
        </div>
        <div className="footer">
          <Typography className="copyright" variant="body2">
            Copyright &copy; 2023 Zero Point | All Rights Reserved
          </Typography>
        </div>
      </div>
    </StyledMenuDiv>
  );
};

export default Menu;
