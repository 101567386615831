import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./Hooks/usePagination.js";
import coloredShard from "../../assets/images/selected.png";
import uncoloredShard from "../../assets/images/unselected.png";
const Pagination = (props: {
  onPageChange: any;
  totalCount: any;
  siblingCount?: 1 | undefined;
  currentPage: any;
  pageSize: any;
  className: any;
}) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange) {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null;
    }
  }
  let lastPage;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  if (paginationRange) {
    lastPage = paginationRange[paginationRange.length - 1];
  }
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      {paginationRange?.map((pageNumber, index) => {
        return (
          <div key={index}>
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber === currentPage ? (
                <img
                  className="pagination-colored-shard"
                  src={coloredShard}
                  alt="Colored Shard"
                />
              ) : (
                <img
                  className="pagination-non-colored-shard"
                  src={uncoloredShard}
                  alt="Non-selected shard"
                />
              )}
            </li>
          </div>
        );
      })}
    </ul>
  );
};

export default Pagination;
