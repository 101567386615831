import styled from "styled-components";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const StyledMuteDiv = styled.div`
  color: #dedede70;
  fill: white;
  .volume-up {
    cursor: pointer;
    font-size: 23px;
    padding: 6px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .volume-off {
    cursor: pointer;
    font-size: 23px;
    padding: 6px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .volume-up:hover,
  .volume-off:hover {
    color: #cbb589;
  }
`;

const Mute = ({ isMuted }: { isMuted: Boolean }) => {
  return (
    <StyledMuteDiv>
      {!isMuted ? (
        <VolumeUpIcon className="volume-up" />
      ) : (
        <VolumeOffIcon className="volume-off" />
      )}
    </StyledMuteDiv>
  );
};

export default Mute;
