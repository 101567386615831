import styled from "styled-components";
import Image from "../components/Image/index";
import BurnBackground from "../assets/images/burnbackground.png";
import BurnLogo from "../assets/images/burnlogo.png";
import { Typography } from "@mui/material";
import HomeNavbar from "../components/NavBar/HomeNavbar";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 850px;
  overflow: hidden;
  .burn-logo {
    padding-top: 50px;
    @media screen and (max-width: 950px) {
      padding-top: 26px;
    }
    @media screen and (max-width: 799px) {
      width: 175px;
    }
    @media screen and (max-width: 510px) {
      padding-top: 10px;
      width: 150px;
    }
    @media screen and (max-width: 330px) {
      width: 50%;
    }
  }
  .heading {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 6px;
    font-family: "Lato";
    font-weight: 300;
    color: #fff;
    font-size: 34px;
    @media only screen and (max-width: 775px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 510px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
  .goldenkey-container {
    padding-top: 40px;
    margin: 0 auto;
    width: 900px;
    margin-bottom: 30px;
    @media only screen and (max-width: 910px) {
      width: 600px;
    }
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .studio-pass-container {
    width: 500px;
    margin: 0 auto;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .studio-pass-video-container {
    width: 600px;
    margin-bottom: 30px;
    @media only screen and (max-width: 400px) {
      margin-bottom: 20px;
    }
  }
  .studio-pass-video {
    border: 1.45px solid #aba14a;
    width: 100%;
    @media only screen and (max-width: 650px) {
      width: 400px;
    }
    @media only screen and (max-width: 450px) {
      width: 300px;
    }
  }
  .sub-header {
    margin: 0;
    color: #cbb589;
    font-family: "Lato";
    font-weight: 300;
    margin-bottom: 0;
    font-size: 24px;
    padding-top: 40px;
    a {
      cursor: pointer;
      padding-bottom: 2px;
      color: #cbb589;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    a:hover {
      color: #fff;
    }
    @media only screen and (max-width: 775px) {
      font-size: 14px;
    }
  }
  .statement {
    margin: 0;
    color: #fff;
    font-family: "Lato";
    font-weight: 300;
    margin-bottom: 0;
    font-size: 24px;
    text-align: center;
    padding: 60px 0;
    a {
      cursor: pointer;
      color: #cbb589;
      text-decoration: none;
    }
    a:hover {
      color: #fff;
    }
    &.mobile {
      display: none;
    }
    @media only screen and (max-width: 775px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 650px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        padding: 60px 10px;
        display: block;
      }
    }
  }
  ul {
    list-style: none;
    li {
      padding-bottom: 20px;
      color: #fff;
      font-family: "Lato";
      font-weight: 300;
      margin-bottom: 0;
      font-size: 24px;
      @media only screen and (max-width: 775px) {
        font-size: 14px;
      }
    }
  }
  .footer {
    margin: 0 auto;
    color: #dedede;
    padding-bottom: 20px;
    @media only screen and (max-width: 650px) {
      font-size: 10px;
    }
  }
  .copyright {
    font-family: "Lato";
    color: #dedede40;
    font-size: 12px;
    padding-top: 150px;
    @media only screen and (max-width: 650px) {
      padding-top: 50px;
      font-size: 10px;
    }
  }
`;

function NaacalPage() {
  return (
    <StyledDiv
      style={{
        background: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${BurnBackground})`,
      }}
    >
      <HomeNavbar />
      <Image
        className="burn-logo"
        src={BurnLogo}
        alt="Zero Point Studios Logo"
      ></Image>
      <h1 className="heading">To whom does the air and the sea belong?</h1>
      <h2 className="statement desktop">
        To unlock the answer to this age-old mystery, <br />
        you must first solve the following:
      </h2>
      <h2 className="statement mobile">
        To unlock the answer to this age-old mystery, <br />
        you must first solve the following:
      </h2>
      <ul>
        <li>1. Genetic experimentation</li>
        <li>2. Revelation</li>
        <li>3. Doorways</li>
        <li>4. Gilea, Serapina and Krona</li>
        <li>5. There are no entries for this clue</li>
        <li>6. Born on the planet K-MES</li>
      </ul>
      <h2 className="sub-header">
        DM your answer to{" "}
        <a
          href="https://twitter.com/LAMAtheAI"
          target="_blank"
          rel="noopener noreferrer"
        >
          LAMA
        </a>{" "}
        on Twitter
      </h2>
      {/* <h2 className="statement">Best of luck Agents, the Lumaris await.</h2> */}
      <div className="footer">
        <Typography className="copyright" variant="body2">
          Copyright &copy; 2023 Zero Point | All Rights Reserved
        </Typography>
      </div>
    </StyledDiv>
  );
}

export default NaacalPage;
